import { title, mlAuto, mrAuto } from "../../../material-kit-pro-react";
import { createStyles } from "@material-ui/core";

const featuresStyle = createStyles({
  featuresSection: {
    padding: "80px 0px"
  },
  textCenter: {
    textAlign: "center"
  },
  title,
  mlAuto,
  mrAuto
});

export default featuresStyle;
