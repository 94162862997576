import {
  title,
  description,
  mrAuto,
  mlAuto
} from "../../../material-kit-pro-react";
import { createStyles } from "@material-ui/core";

const servicesStyle = createStyles({
  title,
  mrAuto,
  mlAuto,
  services: {
    paddingTop: "10px",
    paddingBottom: "80px"
  },
  textCenter: {
    textAlign: "center"
  },
  description
});

export default servicesStyle;
