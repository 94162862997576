import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
// @material-ui/icons
import Menu from '@material-ui/icons/Menu';
import Close from '@material-ui/icons/Close';
// core components
import headerStyle from '../../assets/jss/material-kit-pro-react/components/headerStyle';

type Color =
    'primary'
    | 'info'
    | 'success'
    | 'warning'
    | 'danger'
    | 'transparent'
    | 'white'
    | 'rose'
    | 'dark';

interface IHeaderState {
    mobileOpen: boolean;
}

interface IHeaderProps {
    color?: Color;
    changeColorOnScroll?: {
        color: Color;
        height: number;
    };
    links: ReactNode;
    brand: string;
    fixed?: boolean;
    absolute?: boolean;
}

class Header extends React.Component<
    IHeaderProps & WithStyles<typeof headerStyle>,
    IHeaderState
> {
    constructor(props: IHeaderProps & WithStyles<typeof headerStyle>) {
        super(props);
        this.state = {
            mobileOpen: false
        };
        this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
        this.headerColorChange = this.headerColorChange.bind(this);
    }
    handleDrawerToggle() {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    }
    componentDidMount() {
        if (this.props.changeColorOnScroll) {
            window.addEventListener('scroll', this.headerColorChange);
        }
    }
    headerColorChange() {
        const { classes, color, changeColorOnScroll } = this.props;
        const windowsScrollTop = window.pageYOffset;
        if (windowsScrollTop > changeColorOnScroll.height) {
            document.body
                .getElementsByTagName('header')[0]
                .classList.remove(classes[color]);
            document.body
                .getElementsByTagName('header')[0]
                .classList.add(classes[changeColorOnScroll.color]);
        } else {
            document.body
                .getElementsByTagName('header')[0]
                .classList.add(classes[color]);
            document.body
                .getElementsByTagName('header')[0]
                .classList.remove(classes[changeColorOnScroll.color]);
        }
    }
    componentWillUnmount() {
        if (this.props.changeColorOnScroll) {
            window.removeEventListener('scroll', this.headerColorChange);
        }
    }
    render() {
        const { classes, color, links, brand, fixed, absolute } = this.props;
        const appBarClasses = classNames({
            [classes.appBar]: true,
            [classes[color]]: color,
            [classes.absolute]: absolute,
            [classes.fixed]: fixed
        });
        return (
            <AppBar className={appBarClasses}>
                <Toolbar className={classes.container}>
                    <Button className={classes.title}>
                        <Link to="/">{brand}</Link>
                    </Button>
                    <Hidden
                        smDown
                        implementation="css"
                        className={classes.hidden}
                    >
                        <div className={classes.collapse}>{links}</div>
                    </Hidden>
                    <Hidden mdUp>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={this.handleDrawerToggle}
                        >
                            <Menu />
                        </IconButton>
                    </Hidden>
                </Toolbar>
                <Hidden mdUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={'right'}
                        open={this.state.mobileOpen}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        onClose={this.handleDrawerToggle}
                    >
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={this.handleDrawerToggle}
                            className={classes.closeButtonDrawer}
                        >
                            <Close />
                        </IconButton>
                        <div className={classes.appResponsive}>{links}</div>
                    </Drawer>
                </Hidden>
            </AppBar>
        );
    }
}

// Header.defaultProp = {
//   color: "white"
// };

// Header.propTypes = {
//   classes: PropTypes.object.isRequired,
//   color: PropTypes.oneOf([
//     "primary",
//     "info",
//     "success",
//     "warning",
//     "danger",
//     "transparent",
//     "white",
//     "rose",
//     "dark"
//   ]),
//   links: PropTypes.node,
//   brand: PropTypes.string,
//   fixed: PropTypes.bool,
//   absolute: PropTypes.bool,
//   // this will cause the sidebar to change the color from
//   // this.props.color (see above) to changeColorOnScroll.color
//   // when the window.pageYOffset is heigher or equal to
//   // changeColorOnScroll.height and then when it is smaller than
//   // changeColorOnScroll.height change it back to
//   // this.props.color (see above)
//   changeColorOnScroll: PropTypes.shape({
//     height: PropTypes.number.isRequired,
//     color: PropTypes.oneOf([
//       "primary",
//       "info",
//       "success",
//       "warning",
//       "danger",
//       "transparent",
//       "white",
//       "rose",
//       "dark"
//     ]).isRequired
//   })
// };

export default withStyles(headerStyle)(Header);
