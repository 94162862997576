import { container } from "../../../material-kit-pro-react";
import { createStyles } from "@material-ui/core";

const tabsStyle = createStyles({
  section: {
    padding: "70px 0"
  },
  container,
  textCenter: {
    textAlign: "center"
  }
});

export default tabsStyle;
