import React, { ReactNode } from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';

import buttonStyle from '../../assets/jss/material-kit-pro-react/components/buttonStyle';

type Color =
    'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'danger'
    | 'rose'
    | 'white'
    | 'twitter'
    | 'facebook'
    | 'google'
    | 'linkedin'
    | 'pinterest'
    | 'youtube'
    | 'tumblr'
    | 'github'
    | 'behance'
    | 'dribbble'
    | 'reddit'
    | 'instagram'
    | 'transparent';

type Size = 'sm' | 'lg';

// TODO: Clarify type of className
interface IRegularButtonProps {
    buttonRef?: any;
    classes?: any;
    color?: Color;
    size?: Size;
    simple?: boolean;
    round?: boolean;
    fullWidth?: boolean;
    disabled?: boolean;
    block?: boolean;
    link?: boolean;
    justIcon?: boolean;
    fileButton?: boolean;
    className?: string;
    children?: ReactNode;
    href?: string;
    target?: string;
    rel?: string;
    onClick?(e?: any): void;
}

function RegularButton(props: IRegularButtonProps & WithStyles<typeof buttonStyle>) {
    const {
        classes,
        color,
        round,
        children,
        fullWidth,
        disabled,
        simple,
        size,
        block,
        link,
        justIcon,
        fileButton,
        className,
        ...rest
    } = props;
    const btnClasses = classNames({
        [classes.button]: true,
        [classes[size]]: size,
        [classes[color]]: color,
        [classes.round]: round,
        [classes.fullWidth]: fullWidth,
        [classes.disabled]: disabled,
        [classes.simple]: simple,
        [classes.block]: block,
        [classes.link]: link,
        [classes.justIcon]: justIcon,
        [classes.fileButton]: fileButton,
        [className]: className
    });
    return (
        <Button {...rest} className={btnClasses}>
            {children}
        </Button>
    );
}

// RegularButton.propTypes = {
//   classes: PropTypes.object.isRequired,
//   color: PropTypes.oneOf([
//     "primary",
//     "secondary",
//     "info",
//     "success",
//     "warning",
//     "danger",
//     "rose",
//     "white",
//     "twitter",
//     "facebook",
//     "google",
//     "linkedin",
//     "pinterest",
//     "youtube",
//     "tumblr",
//     "github",
//     "behance",
//     "dribbble",
//     "reddit",
//     "instagram",
//     "transparent"
//   ]),
//   size: PropTypes.oneOf(["sm", "lg"]),
//   simple: PropTypes.bool,
//   round: PropTypes.bool,
//   fullWidth: PropTypes.bool,
//   disabled: PropTypes.bool,
//   block: PropTypes.bool,
//   link: PropTypes.bool,
//   justIcon: PropTypes.bool,
//   fileButton: PropTypes.bool
// };

export default withStyles(buttonStyle)(RegularButton);
// export default RegularButton;
