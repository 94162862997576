import { createStyles } from "@material-ui/core";

const instructionStyle = createStyles({
  instruction: {},
  picture: {},
  image: {
    width: "100%",
    height: "auto",
    borderRadius: "6px",
    display: "block",
    maxWidth: "100%"
  }
});

export default instructionStyle;
