import {
  title,
  description,
  mrAuto,
  mlAuto
} from "../../../material-kit-pro-react";
import customSelectStyle from "../../customSelectStyle";
import { createStyles } from "@material-ui/core";

const contactStyle = createStyles({
  title,
  mrAuto,
  mlAuto,
  ...customSelectStyle,
  description: {
    ...description,
    marginBottom: "70px"
  },
  textCenter: {
    textAlign: "center!important" as "center"
  },
  selectUnderlineRoot: {
    "& > div": {
      marginTop: "13px"
    }
  },
  aboutUs: {
    padding: "80px 0px"
  }
});

export default contactStyle;
