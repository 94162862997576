import React, { useState, useEffect } from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import InputAdornment from '@material-ui/core/InputAdornment'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Icon from '@material-ui/core/Icon'
// @material-ui/icons
import Timeline from '@material-ui/icons/Timeline'
import Code from '@material-ui/icons/Code'
import Group from '@material-ui/icons/Group'
import Face from '@material-ui/icons/Face'
import Email from '@material-ui/icons/Email'
import Check from '@material-ui/icons/Check'
import Favorite from '@material-ui/icons/Favorite'
// core components
import Header from '../../components/Header/Header'
import HeaderLinks from '../../components/Header/HeaderLinks'
import Footer from '../../components/Footer/Footer'
import GridContainer from '../../components/Grid/GridContainer'
import GridItem from '../../components/Grid/GridItem'
import Button from '../../components/CustomButtons/Button'
import Card from '../../components/Card/Card'
import CardBody from '../../components/Card/CardBody'
import InfoArea from '../../components/InfoArea/InfoArea'
import CustomInput from '../../components/CustomInput/CustomInput'

import signupCallbackStyle from '../../assets/jss/material-kit-pro-react/custom/signupCallbackStyle'

import image from '../../assets/img/bg7.jpg'
import { withRouter, Redirect, RouteComponentProps } from 'react-router'
import { InputLabel, FormControl, Select, WithStyles } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import * as firebase from 'firebase'
import qs from 'querystring'

interface ISignupCallbackProps extends WithStyles<typeof signupCallbackStyle>, RouteComponentProps {}

const SignupCallback: React.FC<ISignupCallbackProps> = (props: ISignupCallbackProps) => {
    const { classes, ...rest } = props

    // #region - Hooks

    const [organization, setOrganization] = useState(0)
    const [organizations, setOrgainzations] = useState([])
    // const [isSignedIn, setIsSignedIn] = useState(firebase.auth().currentUser !== null)
    const [isSignedIn, setIsSignedIn] = useState(true)

    useEffect(() => {
        // TODO: Call to WebAPI for list of accounts

        setTimeout(() => {
            setOrgainzations([{ id: 1, name: '10ARK' }, { id: 2, name: 'REMchannel' }])
        }, 2500)
    }, [organizations])

    // #endregion - Hooks

    // #region - Event Handlers

    const handleContinue = () => {
        props.history.push('/install')
    }

    // #endregion - Event Handlers

    useEffect(() => {
        return firebase.auth().onAuthStateChanged(user => {
            console.log(`User from SignupCallback is`, user)

            if (user) {
                const params: string = props.location.search.replace('?', '')

                const { code, state } = qs.parse(params)

                firebase
                    .database()
                    .ref(`users/${state}`)
                    .set({
                        code,
                    })

                setIsSignedIn(true)
            }
        })
    }, [])

    // TODO: Add a timeout if not authenticated for too long
    if (!isSignedIn) {
        return <div>Checking for authorized user</div>
    } else {
        return (
            <div>
                <Header
                    absolute
                    color="transparent"
                    brand="Material Kit PRO React"
                    links={<HeaderLinks dropdownHoverColor="rose" />}
                    {...rest}
                />
                <div
                    className={classes.pageHeader}
                    style={{
                        backgroundImage: 'url(' + image + ')',
                        backgroundSize: 'cover',
                        backgroundPosition: 'top center',
                    }}
                >
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={10} md={10}>
                                <Card className={classes.cardSignup}>
                                    <h2 className={classes.cardTitle}>Select Organization</h2>
                                    <CardBody>
                                        <GridContainer justify="center">
                                            {/* <GridItem xs={12} sm={5} md={5}>
                      <InfoArea
                        className={classes.infoArea}
                        title="Marketing"
                        description="We've created the marketing campaign of the website. It was a very interesting collaboration."
                        icon={Timeline}
                        iconColor="rose"
                      />
                      <InfoArea
                        className={classes.infoArea}
                        title="Fully Coded in HTML5"
                        description="We've developed the website with HTML5 and CSS3. The client has access to the code using GitHub."
                        icon={Code}
                        iconColor="primary"
                      />
                      <InfoArea
                        className={classes.infoArea}
                        title="Built Audience"
                        description="There is also a Fully Customizable CMS Admin Dashboard for this product."
                        icon={Group}
                        iconColor="info"
                      />
                    </GridItem> */}
                                            <GridItem xs={12} sm={5} md={5}>
                                                <form className={classes.form}>
                                                    <FormControl fullWidth className={classes.selectFormControl}>
                                                        <InputLabel
                                                            htmlFor="simple-select"
                                                            className={classes.selectLabel}
                                                        >
                                                            Azure DevOps Organizations
                                                        </InputLabel>
                                                        <Select
                                                            MenuProps={{
                                                                className: classes.selectMenu,
                                                            }}
                                                            classes={{
                                                                select: classes.select,
                                                            }}
                                                            value={organization}
                                                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                                                                setOrganization(Number(event.target.value))
                                                            }
                                                            inputProps={{
                                                                name: 'simpleSelect',
                                                                id: 'simple-select',
                                                            }}
                                                        >
                                                            <MenuItem
                                                                disabled
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                }}
                                                            >
                                                                Select organization
                                                            </MenuItem>

                                                            {organizations.map((item: any) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={item.id}
                                                                        classes={{
                                                                            root: classes.selectMenuItem,
                                                                            selected: classes.selectMenuItemSelected,
                                                                        }}
                                                                        value={item.id}
                                                                    >
                                                                        {item.name}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                    <div className={classes.textCenter}>
                                                        <Button round color="primary" onClick={handleContinue}>
                                                            Continue
                                                        </Button>
                                                    </div>
                                                </form>
                                            </GridItem>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </div>
                    <Footer
                        content={
                            <div>
                                <div className={classes.left}>
                                    <List className={classes.list}>
                                        <ListItem className={classes.inlineBlock}>
                                            <a href="https://www.creative-tim.com/" className={classes.block}>
                                                Creative Tim
                                            </a>
                                        </ListItem>
                                        <ListItem className={classes.inlineBlock}>
                                            <a
                                                href="https://www.creative-tim.com/presentation"
                                                className={classes.block}
                                            >
                                                About us
                                            </a>
                                        </ListItem>
                                        <ListItem className={classes.inlineBlock}>
                                            <a href="//blog.creative-tim.com/" className={classes.block}>
                                                Blog
                                            </a>
                                        </ListItem>
                                        <ListItem className={classes.inlineBlock}>
                                            <a href="https://www.creative-tim.com/license" className={classes.block}>
                                                Licenses
                                            </a>
                                        </ListItem>
                                    </List>
                                </div>
                                <div className={classes.right}>
                                    &copy; {1900 + new Date().getFullYear()} , made with{' '}
                                    <Favorite className={classes.icon} /> by{' '}
                                    <a href="https://www.creative-tim.com">Creative Tim</a> for a better web.
                                </div>
                            </div>
                        }
                    />
                </div>
            </div>
        )
    }
}

export default withStyles(signupCallbackStyle)(withRouter(SignupCallback))
