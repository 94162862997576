import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import * as firebase from 'firebase'

const firebaseConfig = {
    apiKey: 'AIzaSyAQ0EVBqk7Q_dd85NlooeA7-mdAGPO7_lk',
    authDomain: 'ark-rsx-dev.firebaseapp.com',
    databaseURL: 'https://ark-rsx-dev.firebaseio.com',
    projectId: 'ark-rsx-dev',
    storageBucket: 'ark-rsx-dev.appspot.com',
    messagingSenderId: '135500378748',
    appId: '1:135500378748:web:6a50fe972d2ec7d5',
}

console.log('index.tsx')

firebase.initializeApp(firebaseConfig)
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
