import { main } from "../../material-kit-pro-react";
import { createStyles } from "@material-ui/core";

const sectionsPageStyle = createStyles({
  main: {
    ...main
  }
});

export default sectionsPageStyle;
