import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

// @material-ui/icons
import ExpandMore from "@material-ui/icons/ExpandMore";

import accordionStyle from "../../assets/jss/material-kit-pro-react/components/accordionStyle";

type ActiveColor =
  | "primary"
  | "secondary"
  | "warning"
  | "danger"
  | "success"
  | "info"
  | "rose";

interface IAccordionProps extends WithStyles<typeof accordionStyle> {
  active: number | number[];
  activeColor: ActiveColor;
  collapses: any[];
}

interface IAccordionState {
  active: number | number[];
  single: boolean;
}

class Accordion extends React.Component<IAccordionProps, IAccordionState> {
  constructor(props: IAccordionProps) {
    super(props);
    this.state = {
      active: props.active,
      single: false
    };
  }

  componentWillMount() {
    // am facut array din numar ca metoda .find sa functioneze indiferent de ce se intampla.
    if (typeof this.state.active === "number") {
      this.setState({
        active: [this.state.active],
        single: true
      });
    }
  }

  handleChange = (panel: any) => (/*event, expanded*/) => {
    let newArray;

    if (this.state.single) {
      const active = this.state.active as number[];

      if (active[0] === panel) {
        newArray = [];
      } else {
        newArray = [panel];
      }
    } else {
      const active = this.state.active as number[];
      if (active.indexOf(panel) === -1) {
        newArray = [...active, panel];
      } else {
        newArray = [...active];
        newArray.splice(active.indexOf(panel), 1);
      }
    }
    this.setState({
      active: newArray
    });
  };
  render() {
    const { classes, collapses, activeColor } = this.props;
    return (
      <div className={classes.root}>
        {collapses.map((prop, key) => {
          const array = this.state.active as number[];
          let active = false;

          if (array !== undefined) {
            active = array.indexOf(key) !== -1;
          } else {
            active = this.state.active === key;
          }

          const summaryClassName = `${activeColor}ExpansionPanelSummary`;
          const expandedSummaryClassName = `${activeColor}ExpansionPanelSummaryExpaned`;

          this.props.classes;

          return (
            <ExpansionPanel
              expanded={active}
              onChange={this.handleChange(key)}
              key={key}
              classes={{
                root: classes.expansionPanel,
                expanded: classes.expansionPanelExpanded
              }}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                classes={{
                  root: `${classes.expansionPanelSummary} ${summaryClassName}`,
                  expanded: `${
                    classes.expansionPanelSummaryExpaned
                  } ${expandedSummaryClassName}`,
                  content: classes.expansionPanelSummaryContent,
                  expandIcon: classes.expansionPanelSummaryExpandIcon
                }}
              >
                <h4 className={classes.title}>{prop.title}</h4>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.expansionPanelDetails}>
                {prop.content}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}
      </div>
    );
  }
}

// Accordion.defaultProps = {
//   active: -1,
//   activeColor: "primary"
// };

// Accordion.propTypes = {
//   classes: PropTypes.object.isRequired,
//   // index of the default active collapse
//   active: PropTypes.oneOfType([
//     PropTypes.number,
//     PropTypes.arrayOf(PropTypes.number)
//   ]),
//   collapses: PropTypes.arrayOf(
//     PropTypes.shape({
//       title: PropTypes.string,
//       content: PropTypes.node
//     })
//   ).isRequired,
//   activeColor: PropTypes.oneOf([
//     "primary",
//     "secondary",
//     "warning",
//     "danger",
//     "success",
//     "info",
//     "rose"
//   ])
// };

export default withStyles(accordionStyle)(Accordion);
