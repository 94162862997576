import React, { Component } from 'react'
import { createBrowserHistory } from 'history'
import { Router, Route, Switch } from 'react-router'

import './assets/scss/material-kit-pro-react.scss?v=1.3.0'

// pages for this product
import AboutUsPage from './views/AboutUsPage/AboutUsPage'
import BlogPostPage from './views/BlogPostPage/BlogPostPage'
import BlogPostsPage from './views/BlogPostsPage/BlogPostsPage'
import ComponentsPage from './views/ComponentsPage/ComponentsPage'
import ContactUsPage from './views/ContactUsPage/ContactUsPage'
import EcommercePage from './views/EcommercePage/EcommercePage'
import LandingPage from './views/LandingPage/LandingPage'
import LoginPage from './views/LoginPage/LoginPage'
import PresentationPage from './views/PresentationPage/PresentationPage'
import PricingPage from './views/PricingPage/PricingPage'
import ProfilePage from './views/ProfilePage/ProfilePage'
import ProductPage from './views/ProductPage/ProductPage'
import SectionsPage from './views/SectionsPage/SectionsPage'
import ShoppingCartPage from './views/ShoppingCartPage/ShoppingCartPage'
import SignupPage from './custom/SignupPage/SignupPage'
import ErrorPage from './views/ErrorPage/ErrorPage'
import SignupCallback from './custom/SignupCallback/SignupCallback'
import InstallExtension from './custom/InstallExtension/InstallExtension'

var hist = createBrowserHistory()

class App extends Component {
    authorize = () => {
        const clientId = 'E05E2411-CAFB-4B98-B978-E0F4F80D9DCE'
        const state = 'TBA'
        const scope = 'vso.work_full'
        const redirectUri = 'https://hq.10ark.com/callback'

        location.href = `https://app.vssps.visualstudio.com/oauth2/authorize?client_id=${clientId}&response_type=Assertion&state=${state}&scope=${scope}&redirect_uri=${redirectUri}`
    }

    render() {
        return (
            <Router history={hist}>
                <Switch>
                    <Route path="/about-us" component={AboutUsPage} />
                    <Route path="/blog-post" component={BlogPostPage} />
                    <Route path="/blog-posts" component={BlogPostsPage} />
                    <Route path="/components" component={ComponentsPage} />
                    <Route path="/contact-us" component={ContactUsPage} />
                    <Route path="/ecommerce-page" component={EcommercePage} />
                    <Route path="/landing-page" component={LandingPage} />
                    <Route path="/login-page" component={LoginPage} />
                    <Route path="/pricing" component={PricingPage} />
                    <Route path="/profile-page" component={ProfilePage} />
                    <Route path="/product-page" component={ProductPage} />
                    <Route path="/sections" component={SectionsPage} />
                    <Route
                        path="/shopping-cart-page"
                        component={ShoppingCartPage}
                    />
                    <Route path="/signup-page" component={SignupPage} />
                    <Route path="/callback" component={SignupCallback} />
                    <Route path="/install" component={InstallExtension} />
                    <Route path="/error-page" component={ErrorPage} />
                    {/* <Route path="/" component={PresentationPage} /> */}
                    <Route path="/" component={SignupPage} />
                </Switch>
            </Router>
        )
    }
}

export default App
