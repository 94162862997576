import {
  title,
  description,
  mrAuto,
  mlAuto
} from "../../../material-kit-pro-react";
import imagesStyles from "../../imagesStyles";
import { createStyles } from "@material-ui/core";

const imgRaised = imagesStyles.imgRaised;
const rounded = imagesStyles.imgRounded;
const imgFluid = imagesStyles.imgFluid;

const servicesStyle = createStyles({
  title,
  description,
  mrAuto,
  mlAuto,
  textCenter: {
    textAlign: "center!important" as "center"
  },
  office: {
    "& img": {
      margin: "20px 0px"
    }
  },
  imgRaised,
  rounded,
  imgFluid
});

export default servicesStyle;
