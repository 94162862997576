import React, { useState, useEffect } from 'react'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import InputAdornment from '@material-ui/core/InputAdornment'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Icon from '@material-ui/core/Icon'

// @material-ui/icons
import Timeline from '@material-ui/icons/Timeline'
import Code from '@material-ui/icons/Code'
import Group from '@material-ui/icons/Group'
import Face from '@material-ui/icons/Face'
import Email from '@material-ui/icons/Email'
import Check from '@material-ui/icons/Check'
import Favorite from '@material-ui/icons/Favorite'

// core components
import Header from '../../components/Header/Header'
import HeaderLinks from '../../components/Header/HeaderLinks'
import Footer from '../../components/Footer/Footer'
import GridContainer from '../../components/Grid/GridContainer'
import GridItem from '../../components/Grid/GridItem'
import Button from '../../components/CustomButtons/Button'
import Card from '../../components/Card/Card'
import CardBody from '../../components/Card/CardBody'
import InfoArea from '../../components/InfoArea/InfoArea'
import CustomInput from '../../components/CustomInput/CustomInput'

import signupPageStyle from '../../assets/jss/material-kit-pro-react/views/signupPageStyle'

import image from '../../assets/img/bg7.jpg'
import { withRouter } from 'react-router'
import TermsDialog from '../TermsDialog/TermsDialog'
import * as firebase from 'firebase'

const SignupPage: React.FC = (props: any) => {
    const { classes, ...rest } = props

    // #region - Hooks

    const [firstName, setFirstName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [checked, setChecked] = useState(false)
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)

    useEffect(() => {
        window.scrollTo(0, 0)
        document.body.scrollTop = 0
    }, [])

    useEffect(() => {
        if (firstName.trim() !== '' && email.trim() !== '' && password.trim() !== '' && checked) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
    }, [firstName, email, password, checked])

    // useEffect(() => {
    //     return firebase.auth().onAuthStateChanged((user: firebase.User) => {
    //         console.log(`User is`, user)

    //         if (user && !isDisabled) {
    //             const clientId = 'E05E2411-CAFB-4B98-B978-E0F4F80D9DCE'
    //             const state = user.uid
    //             const scope = 'vso.work_full'
    //             const redirectUri = 'https://hq.10ark.com/callback'
    //             const oauthUrl = `https://app.vssps.visualstudio.com/oauth2/authorize?client_id=${clientId}&response_type=Assertion&state=${state}&scope=${scope}&redirect_uri=${redirectUri}`

    //             // Redirect for authorization
    //             window.location.href = oauthUrl
    //         }
    //     })
    // }, [])

    // #endregion - Hooks

    // #region - Event Handlers

    const handleGetStarted = () => {
        firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then((uc: firebase.auth.UserCredential) => {
                console.log(`Created ${email}`)

                const clientId = '25FDC2C0-C980-48B7-83E2-9279D6EA10B0'
                const state = uc.user.uid
                const scope =
                    'vso.entitlements vso.extension.data_write vso.extension_manage vso.identity vso.project_write vso.work_full'
                const redirectUri = 'https://10ark.me/callback'
                const oauthUrl = `https://app.vssps.visualstudio.com/oauth2/authorize?client_id=${clientId}&response_type=Assertion&state=${state}&scope=${scope}&redirect_uri=${redirectUri}`

                // Redirect for authorization
                window.location.href = oauthUrl
            })
    }

    const handleDialogToggle = () => {
        setIsDialogOpen(!isDialogOpen)
    }

    // #endregion - Event Handlers

    return (
        <div>
            <Header
                absolute
                color="transparent"
                brand="10ARK Agile Toolbelt"
                links={<HeaderLinks dropdownHoverColor="rose" />}
                {...rest}
            />
            <div
                className={classes.pageHeader}
                style={{
                    backgroundImage: 'url(' + image + ')',
                    backgroundSize: 'cover',
                    backgroundPosition: 'top center',
                }}
            >
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={10} md={10}>
                            <Card className={classes.cardSignup}>
                                <h2 className={classes.cardTitle}>Signup</h2>
                                <CardBody>
                                    <GridContainer justify="center">
                                        <GridItem xs={12} sm={5} md={5}>
                                            <div className={classes.textCenter}>
                                                <Button justIcon round color="twitter">
                                                    <i className={classes.socials + ' fab fa-twitter'} />
                                                </Button>
                                                <Button justIcon round color="dribbble">
                                                    <i className={classes.socials + ' fab fa-dribbble'} />
                                                </Button>
                                                <Button justIcon round color="facebook">
                                                    <i className={classes.socials + ' fab fa-facebook-f'} />
                                                </Button>
                                                <h4 className={classes.socialTitle}>or be classical</h4>
                                            </div>
                                            <form className={classes.form}>
                                                <CustomInput
                                                    formControlProps={{
                                                        fullWidth: true,
                                                        className: classes.customFormControlClasses,
                                                    }}
                                                    inputProps={{
                                                        startAdornment: (
                                                            <InputAdornment
                                                                position="start"
                                                                className={classes.inputAdornment}
                                                            >
                                                                <Face className={classes.inputAdornmentIcon} />
                                                            </InputAdornment>
                                                        ),
                                                        placeholder: 'First Name...',
                                                        value: firstName,
                                                        onChange: (
                                                            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                                                        ) => setFirstName(e.currentTarget.value),
                                                    }}
                                                />
                                                <CustomInput
                                                    formControlProps={{
                                                        fullWidth: true,
                                                        className: classes.customFormControlClasses,
                                                    }}
                                                    inputProps={{
                                                        startAdornment: (
                                                            <InputAdornment
                                                                position="start"
                                                                className={classes.inputAdornment}
                                                            >
                                                                <Email className={classes.inputAdornmentIcon} />
                                                            </InputAdornment>
                                                        ),
                                                        placeholder: 'Email...',
                                                        value: email,
                                                        onChange: (
                                                            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                                                        ) => setEmail(e.currentTarget.value),
                                                    }}
                                                />
                                                <CustomInput
                                                    formControlProps={{
                                                        fullWidth: true,
                                                        className: classes.customFormControlClasses,
                                                    }}
                                                    inputProps={{
                                                        startAdornment: (
                                                            <InputAdornment
                                                                position="start"
                                                                className={classes.inputAdornment}
                                                            >
                                                                <Icon className={classes.inputAdornmentIcon}>
                                                                    lock_outline
                                                                </Icon>
                                                            </InputAdornment>
                                                        ),
                                                        placeholder: 'Password...',
                                                        value: password,
                                                        type: 'password',
                                                        onChange: (
                                                            e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                                                        ) => setPassword(e.currentTarget.value),
                                                    }}
                                                />
                                                <FormControlLabel
                                                    classes={{
                                                        label: classes.label,
                                                    }}
                                                    control={
                                                        <Checkbox
                                                            tabIndex={-1}
                                                            onClick={() => setChecked(!checked)}
                                                            checkedIcon={<Check className={classes.checkedIcon} />}
                                                            icon={<Check className={classes.uncheckedIcon} />}
                                                            classes={{
                                                                checked: classes.checked,
                                                                root: classes.checkRoot,
                                                            }}
                                                            checked={checked}
                                                        />
                                                    }
                                                    label={
                                                        <span>
                                                            I agree to the{' '}
                                                            <a href="#tsandcs" onClick={handleDialogToggle}>
                                                                terms and conditions
                                                            </a>
                                                            .
                                                        </span>
                                                    }
                                                />
                                                <div className={classes.textCenter}>
                                                    <Button
                                                        round
                                                        color="primary"
                                                        onClick={handleGetStarted}
                                                        disabled={isDisabled}
                                                    >
                                                        Get started
                                                    </Button>
                                                </div>
                                            </form>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                    <TermsDialog isOpen={isDialogOpen} setIsOpen={handleDialogToggle} />
                </div>
                <Footer
                    content={
                        <div>
                            <div className={classes.left}>
                                <List className={classes.list}>
                                    <ListItem className={classes.inlineBlock}>
                                        <a href="https://www.creative-tim.com/" className={classes.block}>
                                            Creative Tim
                                        </a>
                                    </ListItem>
                                    <ListItem className={classes.inlineBlock}>
                                        <a href="https://www.creative-tim.com/presentation" className={classes.block}>
                                            About us
                                        </a>
                                    </ListItem>
                                    <ListItem className={classes.inlineBlock}>
                                        <a href="//blog.creative-tim.com/" className={classes.block}>
                                            Blog
                                        </a>
                                    </ListItem>
                                    <ListItem className={classes.inlineBlock}>
                                        <a href="https://www.creative-tim.com/license" className={classes.block}>
                                            Licenses
                                        </a>
                                    </ListItem>
                                </List>
                            </div>
                            <div className={classes.right}>
                                &copy; {1900 + new Date().getFullYear()} , made with{' '}
                                <Favorite className={classes.icon} /> by{' '}
                                <a href="https://www.creative-tim.com">Creative Tim</a> for a better web.
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    )
}

export default withStyles(signupPageStyle)(withRouter(SignupPage))
