import {
  container,
  mrAuto,
  mlAuto,
  description
} from "../../../material-kit-pro-react";
import { createStyles } from "@material-ui/core";

const descriptionStyle = createStyles({
  container,
  textCenter: {
    textAlign: "center"
  },
  aboutDescription: {
    padding: "70px 0 0 0"
  },
  mrAuto,
  mlAuto,
  description
});

export default descriptionStyle;
