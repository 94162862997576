import React, { useState, useEffect } from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import InputAdornment from '@material-ui/core/InputAdornment'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Icon from '@material-ui/core/Icon'
// @material-ui/icons
import Timeline from '@material-ui/icons/Timeline'
import Code from '@material-ui/icons/Code'
import Group from '@material-ui/icons/Group'
import Face from '@material-ui/icons/Face'
import Email from '@material-ui/icons/Email'
import Check from '@material-ui/icons/Check'
import Favorite from '@material-ui/icons/Favorite'
// core components
import Header from '../../components/Header/Header'
import HeaderLinks from '../../components/Header/HeaderLinks'
import Footer from '../../components/Footer/Footer'
import GridContainer from '../../components/Grid/GridContainer'
import GridItem from '../../components/Grid/GridItem'
import Button from '../../components/CustomButtons/Button'
import Card from '../../components/Card/Card'
import CardBody from '../../components/Card/CardBody'
import InfoArea from '../../components/InfoArea/InfoArea'
import CustomInput from '../../components/CustomInput/CustomInput'

import signupPageStyle from '../../assets/jss/material-kit-pro-react/views/signupPageStyle'

import image from '../../assets/img/bg7.jpg'
import { withRouter } from 'react-router'
import { InputLabel, FormControl, Select } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import CustomLinearProgress from '../../components/CustomLinearProgress/CustomLinearProgress'

const InstallExtension: React.FC = (props: any) => {
    const { classes, ...rest } = props

    // #region - Hooks

    const [isInstalled, setIsInstalled] = useState(false)

    useEffect(() => {
        // Extension data
        const data = {
            organization: 'xyza',
            extension: 'abcd',
        }

        fetch(`URL`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                Authorization: `Bearer TOKEN`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(response => {
                setIsInstalled(true)
            })
            .catch(exception => {})

        // setTimeout(() => {
        //     setIsInstalled(true)
        // }, 4000)
    }, [isInstalled])

    // #endregion - Hooks

    // #region - Event Handlers

    const handleReturn = () => {
        location.href = 'https://10ark.visualstudio.com'
    }

    // #endregion - Event Handlers

    return (
        <div>
            <Header
                absolute
                color="transparent"
                brand="Material Kit PRO React"
                links={<HeaderLinks dropdownHoverColor="rose" />}
                {...rest}
            />
            <div
                className={classes.pageHeader}
                style={{
                    backgroundImage: 'url(' + image + ')',
                    backgroundSize: 'cover',
                    backgroundPosition: 'top center',
                }}
            >
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={10} md={10}>
                            <Card className={classes.cardSignup}>
                                <h2 className={classes.cardTitle}>Install Extension</h2>
                                <CardBody>
                                    <GridContainer justify="center">
                                        <GridItem xs={12} sm={5} md={5}>
                                            <InfoArea
                                                className={classes.infoArea}
                                                title="Installing Extension"
                                                description="We are installing 10ARK Agile Toolbelt into your organization"
                                                icon={Timeline}
                                                iconColor="rose"
                                            />
                                            {isInstalled && <CustomLinearProgress />}
                                            {isInstalled && (
                                                <div className={classes.textCenter}>
                                                    <Button round color="primary" onClick={handleReturn}>
                                                        Return to Azure DevOps
                                                    </Button>
                                                </div>
                                            )}
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
                <Footer
                    content={
                        <div>
                            <div className={classes.left}>
                                <List className={classes.list}>
                                    <ListItem className={classes.inlineBlock}>
                                        <a href="https://www.creative-tim.com/" className={classes.block}>
                                            Creative Tim
                                        </a>
                                    </ListItem>
                                    <ListItem className={classes.inlineBlock}>
                                        <a href="https://www.creative-tim.com/presentation" className={classes.block}>
                                            About us
                                        </a>
                                    </ListItem>
                                    <ListItem className={classes.inlineBlock}>
                                        <a href="//blog.creative-tim.com/" className={classes.block}>
                                            Blog
                                        </a>
                                    </ListItem>
                                    <ListItem className={classes.inlineBlock}>
                                        <a href="https://www.creative-tim.com/license" className={classes.block}>
                                            Licenses
                                        </a>
                                    </ListItem>
                                </List>
                            </div>
                            <div className={classes.right}>
                                &copy; {1900 + new Date().getFullYear()} , made with{' '}
                                <Favorite className={classes.icon} /> by{' '}
                                <a href="https://www.creative-tim.com">Creative Tim</a> for a better web.
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    )
}

export default withStyles(signupPageStyle)(withRouter(InstallExtension))
