/* eslint-disable */
import React, { ReactNode } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";

import footerStyle from "../../assets/jss/material-kit-pro-react/components/footerStyle";
import { Theme } from '@material-ui/core';

interface IFooterProps {
    children?: ReactNode;
    theme?: "dark" | "white" | "transparent";
    big?: Boolean;
    content?: ReactNode;
    className?: string
}

// TODO: Fix any type
function Footer(props: IFooterProps & WithStyles<typeof footerStyle>) {
  const { children, content, classes, theme, big, className } = props;
  const themeType =
    theme === "transparent" || theme == undefined ? false : true;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes[theme as "dark" | "white"]]: themeType,
    [classes.big]: big || children !== undefined,
    [className]: className !== undefined
  });
  const aClasses = classNames({
    [classes.a]: true
  });

  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        {children !== undefined ? (
          <div>
            <div className={classes.content}>{children}</div>
            <hr />
          </div>
        ) : (
          " "
        )}
        {content}
        <div className={classes.clearFix} />
      </div>
    </footer>
  );
}

// Footer.propTypes = {
//   classes: PropTypes.object.isRequired,
//   theme: PropTypes.oneOf(["dark", "white", "transparent"]),
//   big: PropTypes.bool,
//   content: PropTypes.node.isRequired
// };

export default withStyles(footerStyle)(Footer);
